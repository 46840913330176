'use client'

import { LayoutDashboardIcon, LogOutIcon, UserCogIcon, BuildingIcon } from 'lucide-react'
import { Menu, MenuButton, MenuItem, MenuList } from '../Menu'
import Image from 'next/image'
import { useAuth, useOrganizationList, useUser } from '@clerk/nextjs'
import { useEffect } from 'react'

export default function SignedInUserButton () {
  const { user } = useUser()
  const { orgId } = useAuth()

  const { isLoaded, setActive, userMemberships } = useOrganizationList({ userMemberships: { infinite: true } })

  useEffect(function setDefaultActiveOrg () {
    if (!isLoaded) return
    if (userMemberships.count === 0) return
    if (orgId) return // if org is already active, return

    setActive({ organization: userMemberships.data[0].organization.id })
  }, [isLoaded, setActive, userMemberships, orgId])

  if (!user) {
    return null
  }

  return (
    <Menu>
      <MenuButton size='small' circular square style={{ color: '#A48757', backgroundColor: 'rgba(228, 177, 95, 0.2)' }}>
        <Image src={user.imageUrl} alt='User profile image' height={28} width={28} />
      </MenuButton>
      <MenuList>
        <MenuItem href='/dashboard'>
          <LayoutDashboardIcon size={16} />
          Dashboard
        </MenuItem>
        <MenuItem href='/dashboard/profil'>
          <UserCogIcon size={16} />
          Profil
        </MenuItem>
        {user.organizationMemberships.length > 0 && (
          <MenuItem href='/dashboard/organization'>
            <BuildingIcon size={16} />
            Organisation
          </MenuItem>
        )}
        <MenuItem href='/logout'>
          <LogOutIcon size={16} />
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
